import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import BackgroundSlider from 'gatsby-image-background-slider'

const Banner = ({ onLoad }) => (
    <section id="banner">
        <BackgroundSlider 
            style={{
                position: 'inherit',
                height: '850px'
            }}
            onLoad={onLoad}
            initDelay={2}
            transition={2}
            query={useStaticQuery(graphql`
                query {
                backgrounds: allFile (
                        filter: {
                            relativeDirectory: {
                                eq: "background"
                            }
                        },
                        sort: {
                            fields: relativePath,
                            order: ASC
                        }
                    ){
                    nodes {
                    relativePath
                    childImageSharp {
                        fluid (maxWidth: 4000, quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }
                }
                }
            `)}
        />
    </section>
)

export default Banner
