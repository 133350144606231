import React, { useState } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import ClipLoader from "react-spinners/ClipLoader"
import Lottie from 'lottie-react';

import Layout from '../components/Layout'
import Banner from '../components/Banner'
import Info from '../components/Info'

import lottieData from '../assets/icons/lottieData.json'

import {
    getCoverImage as getBangaloCoverImage,
    getTitle as getBangaloTitle,
} from '../messages/bangalo-messages'
import {
    getCoverImage as getCasaCoverImage,
    getTitle as getCasaTitle,
} from '../messages/casa-messages'

import {
    CASA_PATH,
    BANGALO_PATH,
} from '../utils/routes';

const HomeIndex = (props) => {
    const [hasLoaded, setHasLoaded] = useState(false)
    const onLoad = () => setHasLoaded(true)

    return (
        <Layout location={props.location}>
            <Helmet
                title="Casa do Sóter"
                meta={[
                    { name: 'description', content: 'Hospedagem na Lapinha da Serra - MG' },
                    { name: 'keywords', content: 'Lapinha da Serra, Lapinha, hospedagem, acomodação, acomodações, pousada, Serra do Cipó, Minas Gerais, turismo, viagem' },
                    { name: "google-site-verification", content: "VxWoiM2dR31kSfg9uanY4CSo6vrpt0AJgsQg4E70r10"},
                ]}
            />

            <Banner onLoad={onLoad}/>

            <div className="loader">
                <ClipLoader loading={!hasLoaded} color='white' size={70} />
            </div>

            <Info />

            <div id="main">
                <section id="one" className="tiles">
                    <article style={{backgroundImage: `url(${getBangaloCoverImage()})`}}>
                        <header className="major">
                            <h3>{getBangaloTitle()}</h3>
                        </header>
                        <Link to={BANGALO_PATH} className="link primary"></Link>
                        <div className="icon-wrapper">
                            <Lottie 
                                animationData={lottieData}
                                height={50}
                                width={50}
                                loop={true}
                                autoPlay={true}
                                />
                        </div>
                    </article>
                    <article style={{backgroundImage: `url(${getCasaCoverImage()})`}}>
                        <header className="major">
                            <h3>{getCasaTitle()}</h3>
                        </header>
                        <Link to={CASA_PATH} className="link primary"></Link>
                        <div className="icon-wrapper">
                            <Lottie 
                                animationData={lottieData}
                                height={50}
                                width={50}
                                loop={true}
                                autoPlay={true}
                                />
                        </div>
                    </article>
                </section>
            </div>

        </Layout>
    )
}

export default HomeIndex