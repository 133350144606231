import React from 'react'
import { getInfoMessages } from '../messages/components-messages'

const messages = getInfoMessages()

const Info = () => (
    <section id="info" className="style2">
        <div className="inner">
            <header className="major">
                <h2>{messages.title}</h2>
            </header>
            <div className="content">
                <p>{messages.description}</p>
                <p><i><strong>{messages.slogan}</strong></i></p>
            </div>
            <h4 className="align-center">{messages.accommodations}</h4>
            <span className="icon-center fa-arrow-down"/>
        </div>
    </section>
)

export default Info
